<template>
  <span
    v-b-tooltip.hover
    :title="tooltipTitle"
    class="btn svg-icon"
    :class="setClasses"
    @click="onClick"
  >
    <inline-svg :src="iconPath" />
  </span>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconName: {
      type: String,
      required: true,
      default: "",
    },
    iconFolder: {
      type: String,
      required: true,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    iconSize: {
      type: String,
      default: "md",
    },
    tooltipTitle: {
      type: String,
      default: "",
    },
  },

  computed: {
    setClasses() {
      return `svg-icon-${this.iconColor} svg-icon-${this.iconSize}`;
    },
    iconPath() {
      return `${process.env.BASE_URL}media/svg/icons/${this.iconFolder}/${this.iconName}.svg`;
    },
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-table;
}
</style>
