import gql from "graphql-tag";

export const agentsData = gql`
  query agentsData($orderBy: OrderBy, $filters: Filter, $page: Int, $perPage: Int) {
    agents(orderBy: $orderBy, filters: $filters, page: $page, first: $perPage) {
      paginatorInfo {
        total
        perPage
        currentPage
        lastPage
      }

      data {
        user {
          id
          firstName
          lastName
          email
        }
        id
        middleName
        taxNumber
        iban
        phone
        createdAt
        region {
          id
          name
        }
        comment
        status
        bx24LeadId
        type
      }
    }

    regions {
      id
      name
    }
  }
`;
