import gql from "graphql-tag";

export const agentData = gql `
  query companyAgent($userId: ID!) {
    companyAgent(userId: $userId) {
      user {
        id
        firstName
        lastName
        email
        language
        createdAt
      }
      id
      middleName
      taxNumber
      iban
      hash
      phone
      region {
          id
          name
      }
      comment
      status
      createdAt
      type
      companyOwnerId
      accrualPercentage
      affiliated
    }

    regions {
      id
      name
    }
  }
`;