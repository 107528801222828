import gql from "graphql-tag";

export const companiesList = gql`
  query companies {
    companies {
      user {
        id
        firstName
         lastName
      }
      id
      middleName
      accrualPercentage
    }
  }
`;
