import gql from "graphql-tag";

export const updateAgent = gql`
  mutation updateAgent(
    $userId: ID!
    $firstName: String
    $lastName: String
    $middleName: String
    $email: String
    $phone: String
    $taxNumber: String
    $iban: String
    $regionId: ID
    $comment: String
    $status: String
    $language: String
    $type: String
    $accrualPercentage: Float
    $companyOwnerId: Int
  ) {
    updateAgent(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      email: $email
      phone: $phone
      taxNumber: $taxNumber
      iban: $iban
      regionId: $regionId
      comment: $comment
      status: $status
      language: $language
      type: $type
      accrualPercentage: $accrualPercentage
      companyOwnerId: $companyOwnerId
    ) {
      user {
        id
        firstName
        lastName
        email
        language
      }
      id
      middleName
      taxNumber
      iban
      phone
      region {
        id
        name
      }
      comment
      status
      type
      accrualPercentage
      companyOwnerId
    }
  }
`;

export const deleteAgent = gql`
  mutation deleteAgent($userId: ID!) {
    deleteAgent(userId: $userId) {
      id
      user {
        id
      }
    }
  }
`;
