<template>
  <KTCard data-cy="agent-add-page">
    <AgentForm
      ref="agentForm"
      :regions="regions"
      :agent-settings="agentSettings"
      @submitForm="onSubmitForm"
    />

    <template v-slot:footer>
      <div class="d-flex justify-content-start">
        <ButtonElement
          data-cy="submit-btn"
          variant="primary"
          text="save"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement data-cy="back-btn" variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { regionList, agentSettingsList } from "./gql/queries";
import { createAgent } from "./gql/mutations";
import KTCard from "@/components/card/KTCard";
import AgentForm from "../_components/AgentForm";
import ButtonElement from "@/components/ButtonElement";

const CONTENT_WIDTH = 700;

export default {
  name: "AgentAdd",
  components: {
    KTCard,
    AgentForm,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    regions: [],
    agentSettings: {
      types: [],
      accrualPercentages: [],
    },
  }),

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },

  async created() {
    await this.getRegions();
  },

  mounted() {
    this.$setBreadcrumbs(["AgentList"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getRegions() {
      const { regions } = await this.$get(regionList);
      const { agentSettings } = await this.$get(agentSettingsList);

      // TODO: fix when will be country switch
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));
      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
      this.agentSettings.types = agentSettings.types.map((type) => ({
        name: this.$t(`agent.types.${type}`),
        value: type,
      }));
      this.agentSettings.accrualPercentages = agentSettings.accrualPercentages;
    },

    async onClickSubmit() {
      this.$refs.agentForm.submitForm();
    },

    async onSubmitForm(agentData) {
      const createdAgent = await this.$post(createAgent, agentData, {
        delaySuccessNotify: true,
      });

      if (createdAgent) await this.$router.push({ name: "AgentList" });
    },

    onClickBack() {
      this.$router.push({ name: "AgentList" });
    },
  },
};
</script>
