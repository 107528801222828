<template>
  <div>
    <KTCard v-if="agent" data-cy="agent-edit-page">
      <AgentForm
        ref="agentForm"
        :agent="agent"
        :regions="regions"
        @submitForm="onSubmitForm"
      />

      <div class="separator separator-dashed mt-9 mb-7" />

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div>
            <ButtonElement
              data-cy="submit-btn"
              variant="primary"
              text="save"
              class="mr-3"
              @click="onClickSubmit"
            />

            <ButtonElement
              data-cy="back-btn"
              variant="text-primary"
              text="back"
              @click="onClickBack"
            />
          </div>

          <!-- <ButtonElement
            v-b-modal.confirm-modal
            data-cy="delete-btn"
            variant="text-danger"
            text="delete"
          /> -->
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { agentData } from "./gql/queries";
import { updateAgent } from './gql/mutations';
import KTCard from "@/components/card/KTCard";
import AgentForm from "../_components/AgentForm";
import ButtonElement from "@/components/ButtonElement";
import ConfirmModal from "@/components/modal/ConfirmModal";
import ReadOnlyElement from "@/components/ReadOnlyElement";

export default {
  name: "CompanyAgentEdit",

  components: {
    KTCard,
    AgentForm,
    ButtonElement,
    ConfirmModal,
    ReadOnlyElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    agentId: null,
    agent: null,
    regions: [],
  }),

  computed: {
    ...mapState("agent", ["statuses"]),

    locale() {
      return this.$i18n.locale;
    },
  },

  async created() {
    this.agentId = this.$route.params.id;

    await this.getFormData();
  },

  mounted() {
    this.$setBreadcrumbs(["AgentList"]);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getFormData() {
      const { companyAgent, regions } = await this.$get(agentData, { userId: this.agentId });

      // TODO: fix when will be country switch
      // if (agent.region) agent.region.name = agent.region.name[this.locale];
      if (companyAgent.region) companyAgent.region.name = companyAgent.region.name["ua"];

      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
      this.agent = companyAgent;
    },

    async onClickSubmit() {
      this.$refs.agentForm.submitForm();
    },

    async onSubmitForm(agentData) {
      agentData = {
        userId: this.agentId,
        ...agentData,
      };

      await this.$post(updateAgent, agentData);
    },

    onClickBack() {
      this.$router.push({ name: "AgentList" });
    },

    async onConfirmDeleteAgent() {
    //   const deletedAgent = await this.$post(
    //     deleteAgent,
    //     { userId: this.agentId },
    //     { delaySuccessNotify: true }
    //   );

    //   if (deletedAgent) await this.$router.push({ name: "AgentList" });
    },
  },


};
</script>