<template>
  <div>
    <KTCard v-if="agent" data-cy="agent-edit-page">
      <AgentForm
        ref="agentForm"
        :agent="agent"
        :regions="regions"
        :agent-settings="agentSettings"
        @submitForm="onSubmitForm"
      />

      <div class="separator separator-dashed mt-9 mb-7" />

      <ReadOnlyElement :label="$t('label.referral.link')" class="pl-6">
        <template v-slot:body>
          <b-link :href="referralLink" target="_blank" class="referral-link">
            {{ referralLink }}
          </b-link>
        </template>
      </ReadOnlyElement>

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div>
            <ButtonElement
              data-cy="submit-btn"
              variant="primary"
              text="save"
              class="mr-3"
              @click="onClickSubmit"
            />

            <ButtonElement
              data-cy="back-btn"
              variant="text-primary"
              text="back"
              @click="onClickBack"
            />
          </div>

          <ButtonElement
            v-b-modal.confirm-modal
            data-cy="delete-btn"
            variant="text-danger"
            text="delete"
          />
        </div>
      </template>
    </KTCard>

    <KTCard v-if="agent && affiliated.length" data-cy="agent-edit-page" title="Дочірні компанії">
      <OurTable
        data-cy="agents-table"
        table-classes="our-table table-responsive-xxl"
        :items="affiliatedList"
        :fields="affiliatedFields"
      >
        <template v-slot:cell-fullNameAndStatusRegion="{ scopeProps }">
          <div>{{ scopeProps.value.fullName }}</div>
          <StatusRegionItem :status="scopeProps.value.status" :region="scopeProps.value.region" />
        </template>

        <template v-slot:cell-actions="{ scopeProps }">
          <SvgIcon
            data-cy="edit-agent-btn"
            icon-folder="Design"
            icon-name="Edit"
            icon-color="primary"
            icon-size="md"
            @click="onClickEditAgent(scopeProps.item)"
          />
        </template>
      </OurTable>
    </KTCard>

    <ConfirmModal
      :modal-title="$t('page.agentEdit.modalTitle')"
      is-centered
      @actionConfirmed="onConfirmDeleteAgent"
    >
      <template v-slot:body>
        {{ $t("page.agentEdit.modalDescription") }}
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { agentFormData, agentSettingsList } from "./gql/queries";
import { updateAgent, deleteAgent } from "./gql/mutations";
import KTCard from "@/components/card/KTCard";
import AgentForm from "../_components/AgentForm";
import ButtonElement from "@/components/ButtonElement";
import ConfirmModal from "@/components/modal/ConfirmModal";
import ReadOnlyElement from "@/components/ReadOnlyElement";
import { mapState } from "vuex";
import OurTable from "@/components/table/OurTable";
import StatusRegionItem from "../index/_components/StatusRegionItem.vue";
import SvgIcon from "@/components/svg/SvgIcon";

// const CONTENT_WIDTH = 700;

export default {
  name: "AgentEdit",
  components: {
    KTCard,
    AgentForm,
    ButtonElement,
    ConfirmModal,
    ReadOnlyElement,
    OurTable,
    StatusRegionItem,
    SvgIcon,
  },

  mixins: [apolloMixin],

  data: () => ({
    agentId: null,
    agent: null,
    affiliated: [],
    regions: [],
    agentSettings: {
      types: [],
      accrualPercentages: [],
    },
  }),

  computed: {
    ...mapState("agent", ["statuses"]),

    referralLink() {
      return `${window.location.origin}/form?agent=${this.agent?.hash}`;
    },

    locale() {
      return this.$i18n.locale;
    },

    affiliatedFields() {
      return [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "fullNameAndStatusRegion",
          label: this.$t("label.fullName"),
          tdAttr: {
            style: "width: 15%; max-width: 250px;",
          },
        },
        {
          key: "phone",
          label: this.$t("label.phone"),
        },
        {
          key: "email",
          label: this.$t("label.email"),
          type: "email",
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 80px;",
          },
        },
      ];
    },

    affiliatedList() {
      return this.affiliated.map((agent) => {
        const id = agent.user.id;
        const dateCreate = agent.createdAt;
        const firstName = agent.user.first_name;
        const lastName = agent.user.last_name;
        const middleName = agent.middle_name || "";
        const fullName = `${lastName} ${firstName} ${middleName}`;
        const phone = agent.phone;
        const status = this.statuses.find((status) => status.code === agent.status);
        const region = agent.region?.name["ua"];
        const email = agent.user.email;
        const fullNameAndStatusRegion = { fullName, status, region };

        return {
          id,
          dateCreate,
          firstName,
          lastName,
          middleName,
          fullName,
          phone,
          status,
          region,
          email,
          fullNameAndStatusRegion,
        };
      });
    },
  },

  async created() {
    this.agentId = this.$route.params.id;

    await this.getFormData();
  },

  mounted() {
    this.$setBreadcrumbs(["AgentList"]);
    // this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getFormData() {
      const { agent, regions } = await this.$get(agentFormData, { userId: this.agentId });
      const { agentSettings } = await this.$get(agentSettingsList);

      // TODO: fix when will be country switch
      // if (agent.region) agent.region.name = agent.region.name[this.locale];
      // this.regions = regions.map((region) => ({ ...region, name: region.name[this.locale] }));
      if (agent.region) agent.region.name = agent.region.name["ua"];

      this.affiliated = agent.affiliated;
      this.regions = regions.map((region) => ({ ...region, name: region.name["ua"] }));
      this.agent = agent;
      this.agentSettings.types = agentSettings.types.map((type) => ({
        name: this.$t(`agent.types.${type}`),
        value: type,
      }));
      this.agentSettings.accrualPercentages = agentSettings.accrualPercentages;
    },

    async onClickSubmit() {
      this.$refs.agentForm.submitForm();
    },

    async onSubmitForm(agentData) {
      agentData = {
        userId: this.agentId,
        ...agentData,
      };

      await this.$post(updateAgent, agentData);
    },

    onClickBack() {
      this.$router.push({ name: "AgentList" });
    },

    async onConfirmDeleteAgent() {
      const deletedAgent = await this.$post(
        deleteAgent,
        { userId: this.agentId },
        { delaySuccessNotify: true }
      );

      if (deletedAgent) await this.$router.push({ name: "AgentList" });
    },

    onClickEditAgent({ id }) {
      window.location.href = `/admin/agents/${id}`
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-link {
  font-weight: 700;
}
</style>
