<template>
  <div class="form-group">
    <b-form-textarea
      class="app-textarea"
      :class="textareaClasses"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      trim
      no-resize
      @input="onInput"
      @blur="onBlur"
    />

    <label class="form-group__label" :class="labelClasses">
      {{ label }}
    </label>

    <p v-if="withError" class="error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    value: {
      type: String,
      default: "",
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    withError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: function () {
        this.$t("validation.required");
      },
    },

    borderColor: {
      type: String,
      default: "gray",
    },
  },

  computed: {
    textareaClasses() {
      const error = this.withError ? "error" : "";
      const borderColor = `border-${this.borderColor}`;

      return [error, borderColor];
    },

    labelClasses() {
      return {
        required: this.required,
        "error-label": this.withError,
        "label-gray": this.borderColor === "gray" && !this.withError,
        "label-black": this.borderColor === "black" && !this.withError,
      };
    },
  },

  methods: {
    onInput(value) {
      this.$emit("input", value);
    },

    onBlur() {
      this.$emit("blur");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin: 0.7rem 0 2.5rem 0;
  position: relative;
}

.form-control {
  border-radius: 0.25rem;
  font-weight: 700;

  &:focus {
    border-color: $primary !important;
  }

  &:focus + label {
    color: $primary;
  }

  &:disabled {
    border: none;
  }
}

.form-group__label {
  font-size: 1.07rem;
  margin-left: 5px;
  padding: 0 5px;
  position: absolute;
  left: 10px;
  top: -14px;
  background-color: white;
  border-radius: 4px;
  z-index: 1;
}

.form-group__label.label-gray {
  color: $gray-500;
}

.form-group__label.label-black {
  color: $black;
}

.form-group__label.required {
  &:after {
    content: "*";
    position: absolute;
    color: $danger;
    font-weight: bold;
    right: -5px;
  }
}

.error-message {
  position: absolute;
  font-size: 0.9rem;
  color: $danger;
  bottom: -19px;
  left: 10px;
  margin-left: 5px;
  padding: 0 5px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;
  white-space: nowrap;
}

.app-textarea {
  height: 76px;
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
}

.app-textarea.border-black {
  border-color: $black;
}

.app-textarea.error {
  border-color: $danger;
}

.error-label {
  color: $danger;
}
</style>
