<template>
  <div class="wrap">
    <p class="custom-label">
      {{ label }}
    </p>

    <slot name="body">
      <p class="text">
        {{ text }}
      </p>
    </slot>
  </div>
</template>

<script>
export default {
  name: "ReadOnlyElement",
  props: {
    label: {
      type: String,
      default: "",
    },

    text: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  p {
    margin-bottom: 0.5rem;
  }

  .custom-label {
    font-size: 1.07rem;
    color: #b5b5c3;
  }

  .text {
    font-weight: 700;
  }
}
</style>
