import gql from "graphql-tag";

export const updateAgent = gql`
  mutation updateCompanyAgent(
    $userId: ID!
    $firstName: String
    $lastName: String
    $middleName: String
    $email: String
    $phone: String
    $taxNumber: String
    $iban: String
    $regionId: ID
    $comment: String
    $status: String
    $language: String
    $accrualPercentage: Float
  ) {
    updateCompanyAgent(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      email: $email
      phone: $phone
      taxNumber: $taxNumber
      iban: $iban
      regionId: $regionId
      comment: $comment
      status: $status
      language: $language
      accrualPercentage: $accrualPercentage
    ) {
      user {
        id
        firstName
        lastName
        email
        language
      }
      id
      middleName
      taxNumber
      iban
      phone
      region {
        id
        name
      }
      comment
      status
      type
      accrualPercentage
      companyOwnerId
    }
  }
`;

// export const deleteAgent = gql`
//   mutation deleteAgent($userId: ID!) {
//     deleteAgent(userId: $userId) {
//       id
//       user {
//         id
//       }
//     }
//   }
// `;
