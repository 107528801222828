<template>
  <div class="status-region-item">
    <StatusItem
      class="status"
      :data-cy="`${status.code}-status`"
      :color="status.color"
      :label="status.label"
    />

    <div v-if="region" class="line" />

    <span class="region" data-cy="region-cell">{{ region }}</span>
  </div>
</template>

<script>
import StatusItem from "@/components/StatusItem";

export default {
  name: "StatusRegionItem",

  components: {
    StatusItem,
  },

  props: {
    status: {
      type: Object,
      default: () => {},
    },

    region: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.status-region-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .line {
    height: 12px;
    width: 1.5px;
    background-color: #e2e5e8;
    margin: 0 8px;
  }

  .status:deep {
    .status-icon {
      height: 12px;
      min-width: 12px;
    }

    .crop-text {
      font-size: 12px;
    }
  }

  .region {
    font-size: 12px;
  }
}
</style>
