<template>
  <div data-cy="agent-list-page">
    <div class="d-flex justify-content-between mb-4">
      <ButtonElement
        data-cy="filters-btn"
        no-focus
        :variant="filtersBtnVariant()"
        text="filters"
        @click="onClickFilters"
      >
        <span>{{ $t("button.filters") }}</span>
        <span>{{ filtersCounter() }}</span>
      </ButtonElement>

      <ButtonElement
        data-cy="add-agent-btn"
        variant="primary"
        text="add"
        @click="onClickAddAgent"
      />
    </div>

    <KTCard card-class="card-table">
      <OurTable
        data-cy="agents-table"
        table-classes="our-table table-responsive-xxl"
        :pagination-data="paginationData"
        :items="agentList"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="getAgents"
      >
        <template v-slot:cell-fullNameAndStatusRegion="{ scopeProps }">
          <div>{{ scopeProps.value.fullName }}</div>
          <StatusRegionItem :status="scopeProps.value.status" :region="scopeProps.value.region" />
        </template>

        <template v-slot:cell-actions="{ scopeProps }">
          <SvgIcon
            data-cy="edit-agent-btn"
            icon-folder="Design"
            icon-name="Edit"
            icon-color="primary"
            icon-size="md"
            @click="onClickEditAgent(scopeProps.item)"
          />
        </template>
      </OurTable>
    </KTCard>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import dateMixin from "@/mixins/date.mixin";
import { agentsData } from "./gql/queries";
import { serializeFilters } from "@/services/filterBuilder.service";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import SvgIcon from "@/components/svg/SvgIcon";
import ButtonElement from "@/components/ButtonElement";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";
import StatusRegionItem from "./_components/StatusRegionItem.vue";

const CONTENT_WIDTH = 1300;

export default {
  name: "AgentList",
  components: {
    KTCard,
    OurTable,
    SvgIcon,
    ButtonElement,
    PageModal,
    OurFilters,
    StatusRegionItem,
  },

  mixins: [apolloMixin, dateMixin],

  data() {
    return {
      regions: [],
      paginationData: {},
      agents: [],
      appliedFilters: {},
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "fullNameAndStatusRegion",
          label: this.$t("label.fullName"),
          tdAttr: {
            style: "width: 15%; max-width: 250px;",
          },
        },
        {
          key: "phone",
          label: this.$t("label.phone"),
        },
        {
          key: "email",
          label: this.$t("label.email"),
          type: "email",
          tdAttr: {
            style: "width: 18%; max-width: 80px;",
          },
        },
        {
          key: "taxNumber",
          label: this.$t("label.taxNumber._"),
        },
        {
          key: "iban",
          label: this.$t("label.iban"),
          tdAttr: {
            style: "width: 20%; max-width: 350px;",
          },
        },
        {
          key: "type",
          label: "Тип",
          // tdAttr: {
          //   style: "width: 20%; max-width: 350px;",
          // },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 80px;",
          },
        },
      ],
      filterFields: [
        {
          key: "lastName",
          label: this.$t("label.lastName"),
          filter: {
            component: "input",
            type: "text",
            column: "user.lastName",
            operator: "like",
          },
        },
        {
          key: "firstName",
          label: this.$t("label.firstName"),
          filter: {
            component: "input",
            type: "text",
            column: "user.firstName",
            operator: "like",
          },
        },
        {
          key: "middleName",
          label: this.$t("label.middleName"),
          filter: {
            component: "input",
            type: "text",
            column: "user.middleName",
            operator: "like",
          },
        },
        {
          key: "status",
          label: this.$t("label.status"),
          filter: {
            component: "select",
            type: "status",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "region",
          label: this.$t("label.region"),
          filter: {
            component: "select",
            column: "region.id",
            operator: "=",
          },
        },
        {
          key: "phone",
          label: this.$t("label.phone"),
          filter: {
            component: "input",
            type: "number",
            operator: "like",
          },
        },
        {
          key: "email",
          label: this.$t("label.email"),
          type: "email",
          filter: {
            component: "input",
            type: "text",
            column: "user.email",
            operator: "like",
          },
        },
        {
          key: "taxNumber",
          label: this.$t("label.taxNumber.full"),
          filter: {
            component: "input",
            type: "text",
            column: "user.taxNumber",
            operator: "like",
          },
        },
        {
          key: "iban",
          label: this.$t("label.iban"),
          filter: {
            component: "input",
            type: "text",
            column: "user.iban",
            operator: "like",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("agent", ["statuses"]),
    ...mapState("employee", ["agentsFilters"]),

    agentList() {
      return this.agents.map((agent) => {
        const id = agent.user.id;
        const dateCreate = agent.createdAt;
        const firstName = agent.user.firstName;
        const lastName = agent.user.lastName;
        const middleName = agent.middleName || "";
        const fullName = `${lastName} ${firstName} ${middleName}`;
        const taxNumber = agent.taxNumber || "–";
        const iban = agent.iban || "–";
        const phone = agent.phone;
        const status = this.statuses.find((status) => status.code === agent.status);
        //TODO: fix when will be country switch
        // const region = agent.region?.name[this.locale];
        const region = agent.region?.name["ua"];
        const email = agent.user.email;
        const comment = agent.comment;
        const fullNameAndStatusRegion = { fullName, status, region };
        const type = agent.type ? this.$t(`agent.displayTypes.${agent.type}`) : this.$t('agent.displayTypes.default');

        return {
          id,
          dateCreate,
          firstName,
          lastName,
          middleName,
          fullName,
          taxNumber,
          iban,
          phone,
          status,
          region,
          email,
          comment,
          fullNameAndStatusRegion,
          type,
        };
      });
    },

    fieldsForFilters() {
      return this.filterFields.map((field) => {
        if (field.key === "status") {
          field.filter.selectOptions = this.statuses;
          field.filter.trackBy = "code";
        }

        if (field.key === "region") {
          field.filter.selectOptions = this.regions.map((region) => ({
            ...region,
            //TODO: fix when will be country switch
            // name: region.name[this.locale],
            name: region.name["ua"],
          }));
          field.filter.itemLabel = "name";
        }

        return field;
      });
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  async created() {
    if (this.agentsFilters) this.appliedFilters = this.agentsFilters;
    await this.getAgents();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapMutations("employee", ["SET_AGENTS_FILTERS"]),

    async getAgents(page) {
      const sortingOptions = {
        column: "createdAt",
        direction: "desc",
      };
      let filtersOptions = [];
      const isAppliedFilters = !!Object.keys(this.appliedFilters).length;

      if (page && page === this.paginationData.currentPage) return;

      if (isAppliedFilters) {
        filtersOptions = serializeFilters(this.filterFields, this.appliedFilters);
      }

      const { companyAgents, regions } = await this.$get(agentsData, {
        filtersOptions,
        sortingOptions,
        page,
      });

      this.paginationData = companyAgents.paginatorInfo;
      this.agents = companyAgents.data;
      this.regions = regions;
    },

    onClickFilters() {
      this.$refs.filtersModal.showModal();
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    async onApplyFilters(filters) {
      this.appliedFilters = filters;
      this.SET_AGENTS_FILTERS(filters);

      await this.getAgents();
    },

    async onResetFilters() {
      this.appliedFilters = {};
      const filters = null;

      this.$refs.filtersModal.closeModal();

      this.SET_AGENTS_FILTERS(filters);
      await this.getAgents();
    },

    onClickAddAgent() {
      this.$router.push({ name: "CompanyAgentAdd" });
    },

    onClickEditAgent({ id }) {
      this.$router.push({ name: "CompanyAgentEdit", params: { id } });
    },

    filtersBtnVariant() {
      const isAppliedFilters = !!Object.keys(this.appliedFilters).length;

      return isAppliedFilters ? "dark" : "outline-dark";
    },

    filtersCounter() {
      const counter = Object.keys(this.appliedFilters).length;

      return counter ? ` · ${counter}` : "";
    },
  },
};
</script>
