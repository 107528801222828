<template>
  <b-modal
    :id="modalId"
    v-model="showModal"
    data-cy="confirm-modal"
    modal-class="app-modal confirmation-modal"
    :title="modalTitle"
    :centered="isCentered"
  >
    <div class="booking-modal__body">
      <slot name="body"> {{ $t("component.confirmModal.description") }} </slot>
    </div>

    <template v-slot:modal-footer>
      <div class="ml-auto">
        <b-button
          data-cy="decline-btn"
          class="btn btn-hover-light-primary font-weight-bold"
          variant="text-primary"
          @click="onDeclineAction"
        >
          {{ $t("component.confirmModal.btn.decline") }}
        </b-button>

        <b-button
          data-cy="confirm-btn"
          class="btn btn-primary btn-shadow font-weight-bold ml-3"
          variant="primary"
          @click="onConfirmAction"
        >
          {{ confirmButtonTitle }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import i18n from "@/plugins/vue-i18n";

export default {
  props: {
    modalId: {
      type: String,
      default: "confirm-modal",
    },

    modalTitle: {
      type: String,
      default: i18n.t("component.confirmModal.title"),
    },

    isCentered: {
      type: Boolean,
      default: false,
    },

    confirmButtonTitle: {
      type: String,
      default() {
        return this.$t("component.confirmModal.btn.confirm");
      },
    },

    withAutoClose: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    showModal: false,
  }),

  methods: {
    closeModal() {
      this.showModal = false;
    },

    onDeclineAction() {
      this.$emit("actionDeclined");
      this.closeModal();
    },

    onConfirmAction() {
      this.$emit("actionConfirmed");
      if (this.withAutoClose) this.closeModal();
    },
  },
};
</script>
