import gql from "graphql-tag";

export const agentFormData = gql`
  query agentFormData($userId: ID!) {
    agent(userId: $userId) {
      user {
        id
        firstName
        lastName
        email
        language
        createdAt
      }
      id
      middleName
      taxNumber
      iban
      hash
      phone
      region {
        id
        name
      }
      comment
      status
      createdAt
      type
      companyOwnerId
      accrualPercentage
      affiliated
    }

    regions {
      id
      name
    }
  }
`;

export const agentSettingsList = gql`
  query agentSettings {
    agentSettings {
      types
      accrualPercentages
    }
  }
`;
