<template>
  <div data-cy="agent-form">
    <b-row class="mb-7">
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.type)"
          data-cy="type-select"
          :options="agentSettings.types"
          item-label="name"
          :label="$t('agent.type')"
          @select="onSelectType"
          track-by="value"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7" v-if="form.type.value === 'affiliated'">
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.companyOwnerId)"
          data-cy="company-owner-id-select"
          :options="companies"
          item-label="name"
          :label="$t('agent.displayTypes.company')"
          @select="onSelectOwner"
          track-by="value"
          :with-error="errors.companyOwnerId"
          :error-message="$t('validation.required')"
          required
        />
      </b-col>
    </b-row>

    <div class="agent-owner-company" v-if="agent.companyOwnerId">
      <a :href="companyUrl">{{ companyUrl }}</a>
    </div>

    <b-row class="mb-7" v-if="form.companyOwnerId">
      <b-col cols="12">
        <InputElement
          v-model="form.accrualPercentage"
          data-cy="accrual-percentage-input"
          required
          :with-error="errors.accrualPercentage"
          :error-message="accrualPercentageErrorMsg"
          :label="$t('agent.percentages.affiliated')"
          @blur="removeErrorClass('accrualPercentage')"
          @input="removeErrorClass('accrualPercentage')"
          :desc="$t('agent.recommendСommissionPercentage', { percent: agentSettings.accrualPercentages.affiliated })"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7" v-if="form.type.value === 'company'">
      <b-col cols="12">
        <InputElement
          v-model="form.accrualPercentage"
          data-cy="accrual-percentage-input"
          required
          input-type="number"
          :with-error="errors.accrualPercentage"
          :error-message="accrualPercentageErrorMsg"
          :label="$t('agent.percentages.company')"
          @blur="removeErrorClass('accrualPercentage')"
          @input="removeErrorClass('accrualPercentage')"
          :desc="$t('agent.recommendСommissionPercentage', { percent: agentSettings.accrualPercentages.company })"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <InputElement
          v-model="form.lastName"
          data-cy="last-name-input"
          required
          :with-error="errors.lastName"
          :error-message="$t('validation.required')"
          :label="$t('label.lastName')"
          @blur="removeErrorClass('lastName')"
          @input="removeErrorClass('lastName')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <InputElement
          v-model="form.firstName"
          data-cy="first-name-input"
          required
          :with-error="errors.firstName"
          :error-message="$t('validation.required')"
          :label="$t('label.firstName')"
          @blur="removeErrorClass('firstName')"
          @input="removeErrorClass('firstName')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <InputElement
          v-model="form.middleName"
          data-cy="middle-name-input"
          :with-error="errors.middleName"
          :error-message="$t('validation.required')"
          :label="$t('label.middleName')"
          @blur="removeErrorClass('middleName')"
          @input="removeErrorClass('middleName')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <CleaveElement
          :value="phoneWithPlus"
          :options="cleaveOptions"
          data-cy="phone-input"
          required
          :label="$t('label.phone')"
          :with-error="errors.phone"
          :error-message="phoneErrorMsg"
          @input="onChangePhone"
          @blur="removeErrorClass('phone')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <InputElement
          v-model="form.email"
          data-cy="email-input"
          required
          input-type="email"
          :with-error="errors.email"
          :error-message="emailErrorMsg"
          :label="$t('label.email')"
          @blur="removeErrorClass('email')"
          @input="removeErrorClass('email')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.region)"
          data-cy="region-select"
          :options="regions"
          item-label="name"
          :with-error="errors.region"
          :error-message="$t('validation.required')"
          :label="$t('label.region')"
          @select="onSelectRegion"
          @open="removeErrorClass('region')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <TextareaElement
          v-model="form.comment"
          :label="$t('label.comment')"
          data-cy="comment"
          :with-error="errors.comment"
          :error-message="commentErrorMsg"
          @blur="removeErrorClass('comment')"
          @input="removeErrorClass('comment')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-13 mb-7" />

    <b-row class="mb-7">
      <b-col cols="12">
        <InputElement
          v-model="form.taxNumber"
          data-cy="tax-number-input"
          :with-error="errors.taxNumber"
          :error-message="taxNumberErrorMsg"
          :label="$t('label.taxNumber.full')"
          @blur="removeErrorClass('taxNumber')"
          @input="removeErrorClass('taxNumber')"
        />
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12">
        <InputElement
          v-model="form.iban"
          data-cy="iban-input"
          :with-error="errors.iban"
          :error-message="ibanErrorMsg"
          :label="$t('label.iban')"
          @blur="removeErrorClass('iban')"
          @input="removeErrorClass('iban')"
        />
      </b-col>
    </b-row>

    <div class="separator separator-dashed mt-13 mb-7" />

    <b-row>
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.status)"
          :options="statuses"
          type="status"
          data-cy="status-select"
          :label="$t('label.status')"
          required
          :with-error="errors.status"
          :error-message="$t('validation.required')"
          track-by="code"
          @select="onSelectStatus"
          @open="removeErrorClass('status')"
        />
      </b-col>

      <b-col v-if="isExistAgent" cols="12">
        <InputElement disabled :label="$t('label.dateCreate')" :value="form.dateCreate" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import agentStatus from "@/configs/agentStatus.json";
import i18nService from "@/services/i18n.service";
import dateMixin from "@/mixins/date.mixin";
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import TextareaElement from "@/components/form/TextareaElement";
import CleaveElement from "@/components/form/CleaveElement";
import apolloMixin from "@/mixins/apollo.mixin";
import { companiesList } from '../gql/queries'

export default {
  name: "AgentForm",
  components: {
    InputElement,
    MultiselectElement,
    TextareaElement,
    CleaveElement,
  },

  mixins: [dateMixin, apolloMixin],

  props: {
    agent: {
      type: Object,
      default: () => ({}),
    },

    regions: {
      type: Array,
      default: () => [],
    },

    agentSettings: {
      type: Object,
      default: () => ({}),
    },

    company: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    form: {
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "38",
      email: "",
      taxNumber: "",
      iban: "",
      region: "",
      comment: "",
      dateCreate: "",
      status: "",
      type: "",
      companyOwnerId: "",
      accrualPercentage: "",
    },
    errors: {
      lastName: false,
      firstName: false,
      middleName: false,
      phone: false,
      email: false,
      taxNumber: false,
      iban: false,
      region: false,
      comment: false,
      status: false,
      accrualPercentage: false,
      companyOwnerId: false,
    },
    errorsMsgs: {
      accrualPercentage: {
        error: null,
      },
    },
    cleaveOptions: {
      phone: true,
      phoneRegionCode: "UA",
      delimiter: "-",
    },
    phoneMinLength: 11,
    phoneMaxLength: 13,
    commentMaxLength: 255,
    companies: [],
  }),

  computed: {
    ...mapState("agent", ["statuses"]),

    isExistAgent() {
      return Object.keys(this.agent).length;
    },

    phoneWithPlus() {
      return `+${this.form.phone}`;
    },

    phoneNumberLength() {
      const phoneRegExp = /\D/g;

      return this.form.phone.replace(phoneRegExp, "").length;
    },

    taxNumberRegular() {
      const taxNumberRegExp = /^[0-9]{10,12}$/;

      return this.clearSpace(this.form.taxNumber).match(taxNumberRegExp);
    },

    ibanRegular() {
      const ibanRegExp = /^[a-zA-Z]{2}[0-9]{16,30}$/;

      return this.clearSpace(this.form.iban).match(ibanRegExp);
    },

    phoneErrorMsg() {
      return !this.phoneNumberLength
        ? this.$t("validation.required")
        : this.$t("validation.phoneLength");
    },

    emailErrorMsg() {
      return !this.form.email ? this.$t("validation.required") : this.$t("validation.mustBeEmail");
    },

    taxNumberErrorMsg() {
      let error;

      if (this.form.taxNumber && !this.taxNumberRegular) {
        error = this.$t("validation.taxNumberLength");
      }

      return error;
    },

    ibanErrorMsg() {
      let error;

      if (this.form.iban && !this.ibanRegular) {
        error = this.$t("validation.ibanLength");
      }

      return error;
    },

    commentErrorMsg() {
      return this.$t("validation.commentMaxLength", { length: this.commentMaxLength });
    },

    accrualPercentageErrorMsg() {
      const errors = {
        min: this.$t("validation.mineq", {num: 0}),
        max: this.$t("validation.max", {
          num: this.form.type.value === 'company' ? 100 : 5,
        }),
        required: this.$t("validation.required"),
      };

      return errors[this.errorsMsgs.accrualPercentage.error];
    },

    companyUrl() {
      return `${window.location.origin}/admin/agents/${this.agent.companyOwnerId}`;
    },
  },

  watch: {
    agent: {
      handler: "onChangeAgent",
      immediate: true,
      deep: true,
    },

    agentSettings: {
      handler: function() {
        if(!this.form.type && typeof this.agentSettings.types !== "undefined") {
          this.form.type = this.agentSettings.types.length && this.agentSettings.types[0];
        }
      },
      immediate: true,
      deep: true,
    }
  },

  methods: {
    clearSpace(value) {
      const spaceReg = /\s/g;

      return value.replace(spaceReg, "");
    },

    async onChangeAgent(agent) {
      if (this.isExistAgent) {
        const dateCreate = this.$dateConverter(agent.createdAt);
        const firstName = agent.user.firstName;
        const lastName = agent.user.lastName;
        const middleName = agent.middleName || "";
        const phone = agent.phone;
        const email = agent.user.email;
        const taxNumber = agent.taxNumber;
        const iban = agent.iban;
        const status = this.statuses.find((status) => status.code === agent.status);
        const region = agent.region || "";
        const comment = agent.comment;
        const type = this.agentSettings.types.find((type) => type.value === agent.type) || null;
        const accrualPercentage = agent.accrualPercentage;
        let companyOwnerId = null;

        if(type && type.value === 'affiliated') {
          await this.fetchCompanies();

          companyOwnerId = this.companies.find((company) => company.value == agent.companyOwnerId);
        }

        this.form = {
          dateCreate,
          firstName,
          lastName,
          middleName,
          phone,
          email,
          taxNumber,
          iban,
          status,
          region,
          comment,
          type,
          companyOwnerId,
          accrualPercentage,
        };
      } else {
        this.form.status = this.statuses.find((status) => status.code === agentStatus.new);
      }
    },

    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    onChangePhone(phone) {
      this.form.phone = phone;
      this.removeErrorClass("phone");
    },

    onSelectRegion(region) {
      this.form.region = region;
    },

    onSelectStatus(status) {
      this.form.status = status;
    },

    onSelectType(type) {
      this.form.type = type;

      switch (type.value) {
        case "company":
            this.form.accrualPercentage = this.agentSettings.accrualPercentages.company;
          break;

        case "affiliated":
            this.form.accrualPercentage = this.agentSettings.accrualPercentages.affiliated;

            if(this.companies.length === 0) {
              this.fetchCompanies();
            }

          break;
      
        default:
            this.form.accrualPercentage = null;
            this.form.companyOwnerId = null;
          break;
      }
    },

    onSelectOwner(value) {
      this.form.companyOwnerId = value
    },

    isValidForm() {
      const emailRegExp = /.+@.+\..+/i;

      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.lastName = !this.form.lastName;
      this.errors.phone = !this.phoneNumberLength;
      this.errors.phone =
        this.phoneNumberLength < this.phoneMinLength ||
        this.phoneNumberLength > this.phoneMaxLength;
      this.errors.email = !this.form.email || !this.form.email.match(emailRegExp);

      if (this.form.taxNumber) this.errors.taxNumber = !this.taxNumberRegular;
      if (this.form.iban) this.errors.iban = !this.ibanRegular;

      this.errors.comment = this.form.comment?.length > this.commentMaxLength;
      this.errors.status = !this.form.status;

      if(this.form.type.value === "company") {
        if(this.form.accrualPercentage < 0) {
          this.errors.accrualPercentage = true;
          this.errorsMsgs.accrualPercentage.error = "min";
        } else if(this.form.accrualPercentage >= 100) {
          this.errors.accrualPercentage = true;
          this.errorsMsgs.accrualPercentage.error = "max";
        } else if(this.form.accrualPercentage.length < 1) {
          this.errors.accrualPercentage = true;
          this.errorsMsgs.accrualPercentage.error = "required";
        }
      }

      if(this.form.type.value === "affiliated") {
        this.errors.companyOwnerId = !this.form.companyOwnerId;

        if(this.form.accrualPercentage < 0) {
          this.errors.accrualPercentage = true;
          this.errorsMsgs.accrualPercentage.error = "min";
        } else if(this.form.accrualPercentage >= this.form.companyOwnerId.accrualPercentage) {
          this.errors.accrualPercentage = true;
          this.errorsMsgs.accrualPercentage.error = "max";
        } else if(this.form.accrualPercentage.length < 1) {
          this.errors.accrualPercentage = true;
          this.errorsMsgs.accrualPercentage.error = "required";
        }
      }

      return !Object.values(this.errors).some((error) => error);
    },

    submitForm() {
      if (!this.isValidForm()) return;

      const firstName = this.form.firstName;
      const lastName = this.form.lastName;
      const middleName = this.form.middleName;
      const phone = this.form.phone;
      const email = this.form.email;
      const taxNumber = this.form.taxNumber;
      const iban = this.form.iban;
      const regionId = this.form.region.id;
      const comment = this.form.comment;
      const status = this.form.status.code;
      const language = i18nService.getActiveLanguage();
      const type = this.form.type.value;
      const accrualPercentage = parseFloat(this.form.accrualPercentage);
      const companyOwnerId = this.form.companyOwnerId ? parseInt(this.form.companyOwnerId.value) : null;

      const agentData = {
        firstName,
        lastName,
        middleName,
        phone,
        email,
        taxNumber,
        iban,
        regionId,
        comment,
        status,
        language,
        type,
        accrualPercentage,
        companyOwnerId,
      };

      this.$emit("submitForm", agentData);
    },

    async fetchCompanies() {
      const { companies } = await this.$get(companiesList, {}, {withLoader: false});

      this.companies = companies.map((row) => ({
        name: `${row.user.firstName} ${row.user.lastName}`,
        value: row.user.id,
        accrualPercentage: row.accrualPercentage,
      }));
    }
  },
};
</script>

<style scoped lang="scss">
.form-group::v-deep {
  margin-bottom: 0;
}
.agent-owner-company {
  margin: -1rem 0 1rem 0;
}
</style>
