import gql from "graphql-tag";

export const regionList = gql`
  query regions {
    regions {
      id
      name
    }
  }
`;

export const agentSettingsList = gql`
  query agentSettings {
    agentSettings {
      types
      accrualPercentages
    }
  }
`;
