<template>
  <div class="form-group cleave-input-wrap">
    <cleave
      v-model="inputValue"
      :options="options"
      class="form-control app-input"
      :class="inputClasses"
      @blur="onBlur"
    />

    <label class="form-group__label" :class="labelClasses">
      {{ label }}
    </label>

    <p v-if="withError" class="error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
// import "cleave.js/dist/addons/cleave-phone.i18n"; TODO: add all countries
import "cleave.js/dist/addons/cleave-phone.ua";

export default {
  name: "CleaveElement",
  props: {
    label: {
      type: String,
      default: "",
    },

    value: {
      type: String,
      default: "",
    },

    options: {
      type: Object,
      default: () => ({}),
    },

    required: {
      type: Boolean,
      default: false,
    },

    withError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: function () {
        this.$t("validation.required");
      },
    },

    size: {
      type: String,
      default: "md",
    },

    borderColor: {
      type: String,
      default: "gray",
    },
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    inputClasses() {
      const error = this.withError ? "error" : "";
      const size = this.size ? [this.size] : "";
      const borderColor = `border-${this.borderColor}`;

      return [error, size, borderColor];
    },

    labelClasses() {
      const required = this.required ? "required" : "";
      const error = this.withError ? "error-label" : "";
      const color = !this.withError ? `label-${this.borderColor}` : "";

      return [required, error, color];
    },
  },

  methods: {
    onBlur() {
      this.$emit("blur");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin: 0.7rem 0 2.5rem 0;
  position: relative;
}

.form-control {
  border-radius: 0.25rem;
  font-weight: 700;

  &:focus {
    border-color: $primary !important;
  }

  &:focus + label {
    color: $primary;
  }

  &:disabled {
    border: none;
  }
}

.form-group__label {
  font-size: 1.07rem;
  margin-left: 5px;
  padding: 0 5px;
  position: absolute;
  left: 10px;
  top: -15px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;

  .tooltip-icon {
    margin-bottom: 0.1rem;
  }
}

.form-group__label.label-gray {
  color: $gray-500;
}

.form-group__label.label-black {
  color: $black;
}

.form-group__label.required {
  &:after {
    content: "*";
    position: absolute;
    color: $danger;
    font-weight: bold;
    right: -5px;
  }
}

.error-message {
  position: absolute;
  font-size: 0.9rem;
  color: $danger;
  bottom: -19px;
  left: 10px;
  margin-left: 5px;
  padding: 0 5px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;
}

.app-input.border-black {
  border-color: $black;
}

.app-input.error {
  border-color: $danger;
}

.error-label {
  color: $danger;
}

.lg {
  height: calc(1.5em + 1.65rem + 4px);
  padding: 0.825rem 1.42rem;
}

.md {
  padding: 0.825rem 1.42rem;
}
</style>
